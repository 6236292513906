import * as React from 'react';
import GatsbyImage, { FluidObject } from 'gatsby-image';

import { Layout, SEO, Hero, Banner, Contact } from '../components';
import { useGraphQL } from '../hooks';
import config from '../utils/config';

function OurTeamPage() {
  return (
    <Layout>
      <SEO title="Our Team" />
      <OurTeamHero />
      <TeamMembers>
        <Justin />
        <Niccole />
      </TeamMembers>
      <OurTeamBanner />
      <TeamMembers>
        <Kaylah />
        <Rob />
        <Cassanda />
      </TeamMembers>
      <TeamMembers>
        <h2 className="w-full px-6 text-center heading-2">Our Pilots</h2>
        <Dave />
        <Peter />
        <Troy />
      </TeamMembers>
      <Contact bgClassName="bg-grey" />
    </Layout>
  );
}

function OurTeamHero() {
  const { ourTeamHero } = useGraphQL();

  return (
    <Hero image={ourTeamHero.childImageSharp.fluid}>
      <h1 className="heading-1 text-shadow">Our Team</h1>
    </Hero>
  );
}

interface Props {
  image: FluidObject;
  name: string;
  title: string;
  children: React.ReactNode;
  onGrey?: boolean;
}

function TeamMember(props: Props) {
  const { image, name, title, children } = props;
  return (
    <article className="flex flex-col items-center w-full px-6 mt-12 lg:w-1/3">
      <div className="w-64 mt-8 md:max-w-sm">
        <div className="relative h-0 aspect-w-3 aspect-h-4">
          <div className="absolute inset-0 flex">
            <GatsbyImage
              fluid={image}
              imgStyle={{ objectPosition: 'top' }}
              className="flex-1"
            />
          </div>
        </div>
      </div>
      <h2 className="mt-4 text-center heading-2">{name}</h2>
      <h3 className="font-medium text-center uppercase text-md sm:text-2xl text-teal">
        {title}
      </h3>
      <div className="flex items-center w-full mt-8">
        <div className="mx-auto prose text-center">{children}</div>
      </div>
    </article>
  );
}

interface TeamMembersProps {
  children: React.ReactNode;
  onGrey?: boolean;
}

function TeamMembers({ children, onGrey }: TeamMembersProps) {
  return (
    <div
      className={`${
        onGrey ? 'bg-grey' : 'bg-white'
      } relative w-full pb-24 mx-auto md:pb-36 -mt-12 max-w-screen-2xl px-4 sm:px-6 lg:px-8`}
    >
      <svg
        className={`${
          onGrey ? 'text-grey' : 'text-white'
        } absolute inset-x-0 top-0 transform -translate-y-full block w-full h-14 md:h-28`}
        fill="currentColor"
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="0,1 1,0 1,1" />
      </svg>
      <div className="flex flex-wrap justify-center -mx-6">{children}</div>
    </div>
  );
}

function Justin() {
  const { justinMace } = useGraphQL();
  return (
    <TeamMember
      name="Justin Mace"
      title="CEO &amp; Chief Pilot"
      image={justinMace.childImageSharp.fluid}
    >
      <p>
        With over 25 years of experience flying aerial agricultural and
        firebombing aircraft, Justin combines piloting expertise with extensive
        knowledge of the aviation industry. As the CEO of {config.title}, he has
        led the organisation through consistent growth since taking over the
        role in 1995.
      </p>
      <p>
        In 2008, Justin established {config.title} as an industry pioneer by
        being the first to bring the amphibious FireBoss aircraft to the
        Southern Hemisphere.
      </p>
      <p>
        Justin is committed to innovation and development within aerial
        firefighting, keeping {config.title} at the cutting edge of the
        industry.
      </p>
    </TeamMember>
  );
}

function Niccole() {
  const { niccoleMace } = useGraphQL();

  return (
    <TeamMember
      name="Niccole Mace"
      title="Chief Operating Officer — COO"
      image={niccoleMace.childImageSharp.fluid}
      onGrey
    >
      <p>Niccole has been managing the company with Justin since 2009.</p>
      <p>
        Niccole as the DAMP and Safety Officer is committed to ensuring every
        operation runs smoothly which is key to ensuring we continue delivering
        aviation excellence to our clients.
      </p>
      <p>
        Niccole has been instrumental in streamlining business operations,
        systems and processes to provide a seamless experience to each and every
        customer.
      </p>
    </TeamMember>
  );
}

function Kaylah() {
  const { kaylahHacio } = useGraphQL();

  return (
    <TeamMember
      name="Kaylah Hacio"
      title="Relationship Manager"
      image={kaylahHacio.childImageSharp.fluid}
    >
      <p>
        As R&M Aviation’s Relationship Manager, Kaylah brings 16 years of
        business development and project management experience within Macquarie
        Group, where she played a key role in the success of a number of
        large-scale transformation projects.
      </p>
      <p>
        Kaylah is adept at managing the requirements and expectations of our
        clients; whether discussing our capabilities, services, or partnership
        opportunities, she is always readily available to assist.
      </p>
    </TeamMember>
  );
}
function Dave() {
  const { dave } = useGraphQL();

  return (
    <TeamMember
      name="David McAnally"
      title="Pilot"
      image={dave.childImageSharp.fluid}
    >
      <p>
        David has 20 years of Aerial Agriculture operations and 15 years Aerial
        Firefighting experience. David has been with R&M Aviation since 2018
        bringing with him over 10,000 hours flight experience and 3 years Chief
        Pilot experience. David is another in-house trained FireBoss pilot with
        over 500 hours on the FireBoss. David often moves between Australia and
        USA conducting aerial firefighting for their fire season.
      </p>
    </TeamMember>
  );
}
function Peter() {
  const { peter } = useGraphQL();

  return (
    <TeamMember
      name="Peter Jones"
      title="Pilot"
      image={peter.childImageSharp.fluid}
    >
      <p>
        Peter is an owner of an aviation business himself and has a long history
        of working with R&M Aviation as a firebombing pilot. He has over 30
        years and 20,000+ hours of low-level flying experience all across
        Australia, Indonesia and the US.
      </p>
    </TeamMember>
  );
}
function Troy() {
  const { troy } = useGraphQL();

  return (
    <TeamMember
      name="Troy Bentley"
      title="Pilot"
      image={troy.childImageSharp.fluid}
    >
      <p>
        Troy is a very experienced and competent pilot who owns and operates his
        own aviation business from Warrnambool Victoria, he has worked with R&M
        Aviation since 1997 and continues to work as a seasonal firebombing
        pilot with our business with over 500 hours operating the fireboss.
      </p>
    </TeamMember>
  );
}

function Rob() {
  const { robJohnston } = useGraphQL();

  return (
    <TeamMember
      name="Rob Johnston"
      title="Head of Strategy"
      image={robJohnston.childImageSharp.fluid}
      onGrey
    >
      <p>
        Joining the {config.title} team in 2020 as a member of our Board, Rob
        provides strategic planning and transformation direction for the
        organisation.
      </p>
      <p>
        Prior to his role with R&M Aviation, Rob was an Executive Director with
        Macquarie Group, where he worked for over 22 years and was responsible
        for managing their Financial Services division.
      </p>
    </TeamMember>
  );
}

function Cassanda() {
  const { cassandaShafer } = useGraphQL();

  return (
    <TeamMember
      name="Cassanda Shafer"
      title="Accounts Officer"
      image={cassandaShafer.childImageSharp.fluid}
    >
      <p>
        Working with R&M Aviation since 2009, Cassanda has a Diploma in
        Accounting which sees her take expert care of all the organisation’s
        accounting, bookkeeping and finance management needs.
      </p>
      <p>
        With her experience it brings a thorough understanding of the operations
        of the aircraft where she has developed accurate and effective systems
        for recording, maintaining and transferring the paperwork from the field
        to the office.
      </p>
    </TeamMember>
  );
}

function OurTeamBanner() {
  const { ourTeamBanner } = useGraphQL();

  return <Banner image={ourTeamBanner.childImageSharp.fluid} />;
}

export default OurTeamPage;
